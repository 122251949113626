var pawLibrary = {
	map: false,
	fbAccessToken: "1046599778716357|Ru5dCXFhXsqfy2-ziNbyZEGmOhs",
	monthNames: ["Jan","Feb","Mar","Apr","May","June","Jul","Aug","Sept","Oct","Nov","Dec"],
	fullMonthNames: ["January","February","March","April","May","June","July","August","September","October","November","December"],
	loading: "<div id=\"loader\" class=\"center\" style=\"width:100%\"><img src=\"./imgs/loader.gif\" alt=\"Loading...\"  /></div>",
	markers: [],
	offset: 0.8,
	dropdownMenus: function()
	{
		$('.parent > a').on('click', function(e) {
			var selected = $(this);
			var id = selected.attr('id');
			if(id != '') id = id.replace('showMenu','');

			if($('#subMenu' + id).length == 1)
			{
				e.preventDefault();
			}

			// Show
			if(selected.hasClass('highlight') == true) {
				// Hide
				selected.removeClass('highlight');
				$('.parent-sub').hide();
			} else {
				// Show
				selected.addClass('highlight');
				$('.parent-sub').hide();
				$('.parent a').removeClass('highlight');
				$('#subMenu' + id).fadeIn();
				$('#showMenu' + id).addClass('highlight');
			}
		});
	},
	search: function(term)
	{
		$('#searchresults-wrap').html('<div id="loading">Searching...</div>').load('ajax/search.inc.php?term=' + term);
	},
	validateForm: function()
	{
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if(name_txt.val().length < 3)
		{
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if( !filter.test(email_txt.val()) )
		{
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if(message_txt.val().length < 3)
		{
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function(elemId, username)
	{

		$.ajax({
			type: 'json',
		    url: '../ajax/getTwitter.inc.php?username=' + username,
		    beforeSend: function() {
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="'+username+'" class="loader" />');
		    },
		    success: function(data){
		    	var img = data[0].image;
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="'+img+'" alt="'+username+'" class="profile" />');
		    }
		});

	},

	generateMap: function(elem,lat,lng, zoomLevel) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: zoomLevel,
		  center: myLatlng,
      scrollwheel: false,
			draggable: true,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		}

		this.map = new google.maps.Map(document.getElementById(elem), myOptions);
	},

	addMarker: function(lat, lng, location_title, location_add) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var contentString = '<div id="mapContent" class="infoMarker">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: this.map,
			title: location_title
		});

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(this.map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});
	},
	centerMap: function() {
		var center = this.map.getCenter();
		this.map.setCenter();
	},

	setMap : function(elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 14,
		  center: myLatlng,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: drag
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if(drag == true) {
			google.maps.event.addListener(marker, 'dragend', function() {
					var point = marker.position;
					if(lat_txt) lat_txt.value = point.$a;
					if(long_txt) long_txt.value = point.ab;

				});
		}

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});

	},

	setMapByGeocode : function(elemId, address, zoomlevel) {

		var mapOptions = {
		  zoom: zoomlevel,
          scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: false
		}

		var contentString = '<div id="mapContent">'+
				'<p>'+address+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(address)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
            content: contentString
         });

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode( {
			'address': address
				}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						var marker = new google.maps.Marker({
							map: map,
							position: results[0].geometry.location
						});

						google.maps.event.addListener(marker, 'click', function() {
						  infowindow.open(map,marker);
						});

					  }
					}
		);

	},

	mobileNav: function()
	{
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-wrap-inner');
		var secondMenu = mobileNavItems.find('ul.second');
		if( mobileBtn.length > 0 )
		{
			// Load Menu
			mobileBtn.click(function() {
				if(mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function(e) {
				var clicked = e.currentTarget.id.replace("showMenu","");
				var target = mobileNavItems.find('#subMenu' + clicked);

				secondMenu.slideUp(function() {
					secondMenu.removeClass('open');
				});

				target.slideDown(function() {
					target.addClass('open');
				});
			});
		}

		$('#mobile-nav-inner-items').hcSticky({
			bottom:0
		});

	},

	dateSelector: function() {
		if($('.datepicker').length > 0)
		{
			var d = new Date();
			var today = d.getDate() + " " + this.monthNames[d.getMonth()] + " " + d.getFullYear().toString().substr(2,2);
			$(".datepicker").minical({
				trigger: '.datepicker',
				from: new Date(),
				initialize_with_date: false
			}).val('Starting ' + today);
		}
	},

	inlineEnquiries: function()
	{
		var enquiry_btn = $('#enquiry_btn');

		enquiry_btn.click(function(e) {
			var title = $('.productDetails h1').html();
			e.preventDefault();
			enquiry_btn.fancybox({
					fitToView	: false,
					autoSize	: true,
					type: 'ajax',
					padding: 0,
					href: 'frontend/view/inc/forms/enquiry.inc.php',
					beforeShow: function() {
						$('.fancybox-inner h3').html("Enquire About " + title);
        		$("body").css({'overflow-y':'hidden'});
					},
					afterClose: function() {
        		$("body").css({'overflow-y':'visible'});
					},
					afterShow: function() {
						var form = $('.fancybox-inner form');

						form.submit(function(e) {
							e.preventDefault();
							var loader = $('#loader');
							var data = {};
								data['name_txt'] = $('.fancybox-inner form #name_txt').val();
								data['email_txt'] = $('.fancybox-inner form #email_txt').val();
								data['telephone_txt'] = $('.fancybox-inner form #telephone_txt').val();
								data['message_txt'] = $('.fancybox-inner form #message_txt').val();
								data['pageName'] = title;
								data['list_txt'] = "";
								data['url'] = 'http://' + window.location.hostname + window.location.pathname;

							form.hide();
							loader.fadeIn();


							$.ajax({
								type: 'POST',
								url: './ajax/sendEnquiry.inc.php',
								data: data,
							}).done(function(response) {
								loader.hide();
								form.html(response).show();
							});

							return false;
						});
					}
				});


		});
	},

	onResize: function()
	{

	},

	setGallery: function()
	{

		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 2000);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn" class="rightBtn"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn" class="leftBtn"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	banners: function() {
		var banners = $('#banners');

		if(banners.length > 0) {
			banners.slick({
				dots: true,
			  infinite: true,
			  speed: 500,
			  fade: true,
				nextArrow: '<span id="rightBannerBtn" class="rightBannerBtn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
				prevArrow: '<span id="leftBannerBtn" class="leftBannerBtn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
			  cssEase: 'linear',
        appendDots: '.banner-paging .inner-wrap',
				slide: '.item'
			});
		}
	},

	products: function() {

	},

	setMinorTabs: function() {
		if($('#minor-tabs').length > 0) {
			var tabs = $('#minor-tabs');
			var btns = tabs.find('li');
			var anchors = tabs.find('a');
			var current = 0;

			btns.unbind().click(function(e) {
				var selected = $(this).attr('id').replace("btn_", "");
				if(current != selected) {
					// selected tab
					btns.removeClass('selected');
					$('#btn_' + selected).addClass('selected');
					// selected textarea
					$('#item_' + current).addClass('hidden');
					$('#item_' + selected).fadeIn().removeClass('hidden').css("display","inline-block");
					// updated current
					current = selected;
				}
			});

		}
	},

	validateEmail: function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
	},

	setTabs: function() {
		if($('#tabs').length > 0) {
			var width = $(window).width();
			var tabs = $('#tabs');
			var btns = tabs.find('li');
			var anchors = tabs.find('a');
			var current = 0;

			if(width > 930) {
				anchors.unbind().click(function(e) {
					e.preventDefault();
				});
				$('#item_' + current).removeClass('hidden');

				btns.unbind().click(function(e) {
					var selected = $(this).attr('id').replace("btn_", "");
					if(current != selected) {
						// selected tab
						btns.removeClass('selected');
						$('#btn_' + selected).addClass('selected');
						// selected textarea
						$('#item_' + current).addClass('hidden');
						$('#item_' + selected).fadeIn().removeClass('hidden').css("display","inline-block");
						// updated current
						current = selected;
					}
				});
			} else {
				anchors.unbind();
				$('.txt-item-wrap').addClass('hidden').attr('css');
			}
		}

	},

	setGallery: function()
	{
		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn-gallery').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 1600);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn-gallery" class="rightBtn-gallery"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn-gallery" class="leftBtn-gallery"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	jumpToLinks: function()
	{
		$('a[href^=#]').click(function(e){
    	var name = $(this).attr('href').substr(1);
    	var pos = $('#'+name).offset();
    	$('body').animate({ scrollTop: pos.top });
    	e.preventDefault();
  	});
	},

	productGallery: function() {
		if( $('#thumbs').length > 0 ) {
			$('#gallery').on('cycle-next cycle-prev', function(e, opts) {
				$('#thumbs').cycle('goto', opts.currSlide);
			});

			$('#thumbs span.thumb-wrap').click(function(){
				var index = $('#thumbs').data('cycle.API').getSlideIndex(this);
				$('#gallery').cycle('goto', index);
			});
		}
	},
	insertParam: function(key, value, loc)
	{
		var params = false;

		if( loc == false )
		{
		    var loc = location.href;
		    	loc += '?cat_sel=&capacity_sel=&type_sel=';
		}

	    if(loc.indexOf("?") !== -1) {
	       loc = loc.split('?');
	       params = loc[1];
	       loc = loc[0];
	    }
	    loc = loc + '?';

	    if(params != false)
	    {
		    params = params.split('&');
		    var x;
		    for(x =0; x < params.length; x++)
		    {
		    	var tmp = params[x].split('=');
		    	if(tmp[0]==key) {
		    		loc += key + '=' + value + '&';
		    	} else {
		    		loc += tmp[0] + '=' + tmp[1] + '&';
		    	}
		    }
		    loc = loc.slice(0,-1);
		} else {
			loc += key + '=' + value;
		}

		return loc;
	},

	updateProductURL: function(url)
	{
		window.history.pushState(null, null, url);
	},

	isValidEmailAddress: function(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
	},

	getDialCode: function()
	{
		var getCode = $('.selected-flag .selected-dial-code');
		return getCode.html();
	},

	separateDialCode: function() {
		$("#telephone_txt").intlTelInput({
			autoHideDialCode: false,
			initialCountry: 'GB',
			preferredCountries: ["gb", "ie"],
			separateDialCode: true
		});

		$('form').submit(function(e) {
			var code = pawLibrary.getDialCode();
			var telephone_txt = $("#telephone_txt");
			telephone_txt.val(code + " " + telephone_txt.val());
		});
	},

	hideBlocks: function(blocks, offset) {
		blocks.each(function(){
			( $(this).offset().top > $(window).scrollTop()+$(window).height()*offset ) && $(this).find('.cd-timeline-ico, .cd-timeline-content').addClass('is-hidden');
		});
	},

	showBlocks: function(blocks, offset) {
		blocks.each(function(){
			( $(this).offset().top <= $(window).scrollTop()+$(window).height()*offset && $(this).find('.cd-timeline-ico').hasClass('is-hidden') ) && $(this).find('.cd-timeline-ico, .cd-timeline-content').removeClass('is-hidden').addClass('bounce-in');
		});
	},

	timeline: function() {
		var timelineBlocks = $('.cd-timeline-block');

		//hide timeline blocks which are outside the viewport
		pawLibrary.hideBlocks(timelineBlocks, pawLibrary.offset);

		//on scolling, show/animate timeline blocks when enter the viewport
		$(window).on('scroll', function(){
			(!window.requestAnimationFrame)
				? setTimeout(function(){ pawLibrary.showBlocks(timelineBlocks, pawLibrary.offset); }, 100)
				: window.requestAnimationFrame(function(){ pawLibrary.showBlocks(timelineBlocks, pawLibrary.offset); });
		});
	},

	init: function()
	{
		pawLibrary.mobileNav();
		pawLibrary.inlineEnquiries();
		pawLibrary.banners();
		pawLibrary.jumpToLinks();
		pawLibrary.dropdownMenus();
		pawLibrary.setGallery();

		if( $('#telephone_txt').length > 0 ) {
			pawLibrary.separateDialCode();
		}

		if($('#tabs').length > 0) {
			var tabs = $('#tabs-btn span');
			var tabsContent = $('.tab-content-inner');
			tabsContent.addClass('hidden');
			$('.tab-content-inner:first').removeClass('hidden');
			tabs.click(function() {
				var selected = $(this).attr('data-tab-btn');
				tabs.removeClass('selected');
				$('*[data-tab-btn="' + selected + '"]').addClass('selected');
				//
				tabsContent.hide().addClass('hidden');
				$('#tab' + selected + '-txt').removeClass('hidden').fadeIn();
			});

			var topLevel = $('a.toggle');
			topLevel.click(function(e) {
		  	e.preventDefault();
		    var $this = $(this);
				topLevel.removeClass('active');
		    if ($this.next().hasClass('show')) {
		        $this.next().removeClass('show');
		        $this.next().slideUp(350);
		    } else {
		        $this.parent().parent().find('li .inner').removeClass('show');
						$this.parent().parent().find('li .inner').slideUp(350);
		        $this.next().toggleClass('show');
		        $this.next().slideToggle(350);
						$($this[0]).addClass('active');
		    }
			});
		}

		if($('#gallery').length > 0) {
			pawLibrary.productGallery();
		}

		if($('#spec-btns').length > 0) {
			var spec_tabs = $('#spec-btns li');
			var spec_content = $('.spec-items-inner');
			spec_tabs.click(function() {
				var selected = $(this).attr('id').replace("tab-btn-", "");
				spec_tabs.removeClass('selected');
				$('#tab-btn-' + selected).addClass('selected');
				//
				spec_content.hide();
				$('#spec-content-' + selected).fadeIn();
			});
		}

		var fancyBox = $('.fancybox');
		if(fancyBox.length > 0) {
			fancyBox.fancybox({
				padding: 0
			});
		}

		if( $('.filterItems').length > 0 )
		{
			$('.filterItems').on('change', function() {
				pawLibrary.productSearch();
			});
		}

		var base = $('base').attr('href');
		$('#mobile-sub-menu').on('change', function() {
			var value = $(this).val().replace("./", "");
			window.location = base + value;
		});

		if($('#about-timeline-js').length > 0) {
			pawLibrary.timeline();
		}

		if($('#location_txt').length > 0) {
			pawLibrary.searchSuppliers();
		}

		$('#request').fancybox({
			afterLoad: function() {
				var url = window.location.href;
						url = url.split('#');
						url = url[0];

				var tmp = $('#product-title').html();

				$('#url_txt').val(url);
				$('#title_txt').val(tmp);
			},
			onComplete: function( instance, slide ) {
				var loading = $('#loader');
				var form = $('#enquiry-frm');

				var name_txt = form.find('#name_txt');
				var email_txt = form.find('#email_txt');
				var msg_txt = form.find('#msg_txt');

				var name_err = form.find('#error-name');
				var email_err = form.find('#error-email');
				var msg_err = form.find('#error-msg');

				//$("#tel_txt").intlTelInput({
				//	autoHideDialCode: false,
				//	initialCountry: 'GB',
				//	preferredCountries: ["gb", "ie"],
				//	separateDialCode: true
				//});

				$('#send_msg_btn').click(function(e) {
					e.preventDefault();
					var errors = false;

					// validation
					if(name_txt.val() == '') {

						name_txt.addClass('error-input');
						name_err.fadeIn();
						errors = true;
					} else {
						name_txt.removeClass('error-input');
						name_err.hide();
					}
					if(!pawLibrary.isValidEmailAddress(email_txt.val())) {
						email_txt.addClass('error-input');
						email_err.fadeIn();
						errors = true;
					} else {
						email_txt.removeClass('error-input');
						email_err.hide();
					}
					if(msg_txt.val() == '') {
						msg_txt.addClass('error-input');
						msg_err.fadeIn();
						errors = true;
					} else {
						msg_txt.removeClass('error-input');
						msg_err.hide();
					}

					// sent
					if(errors == false) {
						loading.removeClass('hide').fadeIn();
						form.hide();
						var formData = $('#enquiry-frm').serialize();
						$.ajax({
							url: './ajax/sendEnquiry.inc.php?init=sendEnquiry',
							data: formData,
							method: 'POST'
						}).done(function(response) {
							$('#loader').html("Your message has been sent");
						});
					}
				});
			}
		});

	}

}


$(window).load(function() {
	pawLibrary.init();
});

$(window).ready(function() {
	if($('#cd-timeline').length > 0) {
		pawLibrary.timeline();
	}
});
